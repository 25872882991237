import React, { useState } from 'react'
import { connect } from 'react-redux'
import Button from '../Common/Button'
import * as renaultProjectModeActionCreators from '../../../state/actions'

function Footer({ initNewProject }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateProject = () => {
    setIsLoading(true)
    initNewProject().then(project => {
      window.location.assign(`/project-mode/project/edit?id=${project.id}`)
    })
  }

  return (
    <div className="search-footer">
      <div className="create-project-button-wrapper">
        <Button
          label="Create project"
          onClick={handleCreateProject}
          size="large"
          disabled={isLoading}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(Footer)

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import '../../styles/search.scss'
import Layout from 'layout'
import SearchHeader from '../components/Search/Header'
import SearchFooter from '../components/Search/Footer'
import SearchContent from '../components/Search/SearchContent'
import NotFoundPage from '../../../../pages/404'
import * as renaultProjectModeActionCreators from '../../state/actions'
import { isRenaultProjectModeEnabled } from '../../../../../va-corejs-v3/utils'

function SearchPage({ systemLoaded, searchProjects, authentication }) {
  const [isSearching, setIsSearching] = useState(true)
  const hasPermission = authentication?.user?.parsedPermissions?.renaultProjectMode

  useEffect(() => {
    async function _search() {
      await searchProjects()
      setIsSearching(false)
    }

    if (systemLoaded) {
      _search()
    }
  }, [systemLoaded])

  if (!isRenaultProjectModeEnabled() || !hasPermission) {
    return (
      <Layout>
        <NotFoundPage />
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="search-page">
        <SearchHeader />
        <SearchContent isSearching={isSearching} />
        <SearchFooter />
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    systemLoaded: state.environment.systemLoaded,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(SearchPage)

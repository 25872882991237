import moment from 'moment/moment'
import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import UserIcon from '../../../../../../assets/svg/auth-icon.svg'
import EditIcon from '../../../../../../assets/svg/edit-scoring-icon.svg'

function ProjectCardFooter({ project }) {
  const date = moment(project.created_at).format('DD.MM.YYYY')

  return (
    <div className="project-card__footer">
      <div className="project-card-date">
        <span className="project-card-date__icon">
          <UserIcon />
        </span>
        <span className="project-card-date__text">{date}</span>
      </div>
      <div className="project-card__edit-button">
        <EditIcon width="16" height="16" className="theme_svg" />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCardFooter)

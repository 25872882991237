import React from 'react'
import { connect } from 'react-redux'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import '../../../../styles/project-cards.scss'
import ProjectCounters from './ProjectCounters'
import ProjectCover from './ProjectCover'
import ProjectCardFooter from './Footer'
import ProjectBrand from './ProjectBrand'

function ProjectCard({ project, texts }) {
  return (
    <div className="project-card">
      {project && (
        <a href={`/project-mode/project/edit?id=${project.id}`} rel="noreferrer">
          <ProjectCover project={project} />
          <div className="project-card__body">
            <ProjectBrand project={project} />
            <div className="project-card__title-wrapper">
              <div className="project-card__name">{project.name ?? texts.project_name}</div>
              <ProjectCounters project={project} />
            </div>
          </div>
          <ProjectCardFooter project={project} />
        </a>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCard)
